var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('TTView',[_c('VRow',[_c('VCol',[_c('VCard',[_c('VToolbar',{attrs:{"flat":""}},[_c('VBtn',{attrs:{"icon":""},on:{"click":function($event){return _vm.$router.go(-1)}}},[_c('VIcon',[_vm._v("fal fa-chevron-left")])],1),_c('VToolbarTitle',[_vm._v("Ответ")]),_c('VSpacer'),_c('VTooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('VBtn',_vm._g(_vm._b({staticClass:"mr-4",attrs:{"icon":"","depressed":"","color":"warning","small":"","to":{
                  name : _vm.Names.R_TALENT_TEST_TOPIC_QUESTION_ANSWER_EDIT,
                  params : {
                    testId: _vm.testId,
                    topicId: _vm.topicId,
                    answerId: _vm.answerId
                  }
                }}},'VBtn',attrs,false),on),[_c('VIcon',{attrs:{"small":""}},[_vm._v(" fal fa-edit ")])],1)]}}])},[_c('span',[_vm._v("Редактирование ответа")])]),_c('VTooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('VBtn',_vm._g(_vm._b({staticClass:"mr-4",attrs:{"icon":"","depressed":"","color":"red","small":""},on:{"click":function($event){return _vm.removeAnswer(_vm.testId, _vm.questionId, _vm.answerId)}}},'VBtn',attrs,false),on),[_c('VIcon',{attrs:{"small":""}},[_vm._v(" fal fa-trash ")])],1)]}}])},[_c('span',[_vm._v("Удаление ответа")])])],1),_c('VCardText',[_c('VRow',[_c('VCol',{attrs:{"cols":"6"}},[_c('VExpansionPanels',{attrs:{"accordion":"","flat":""}},[_c('VExpansionPanel',[_c('VExpansionPanelHeader',[_vm._v("JSON")]),_c('VExpansionPanelContent',[_c('code',{staticClass:"elevation-0 d-block",staticStyle:{"white-space":"pre"}},[_vm._v(" "+_vm._s(_vm.answer)+" ")])])],1)],1),_c('VDivider',{staticClass:"my-4"}),_c('div',[_c('div',{staticClass:"rounded pa-2 mb-2",staticStyle:{"border":"1px solid lightgray"}},[_c('h5',[_vm._v(" Текст (оригинальный) ")]),_c('p',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.answer.originalText)+" ")])]),(_vm.answer.text)?_c('VExpansionPanels',{staticClass:"my-4"},[_c('VExpansionPanel',[_c('VExpansionPanelHeader',[_vm._v("Текст (HTML)")]),_c('VExpansionPanelContent',[_c('VCard',{staticClass:"pa-2",attrs:{"outlined":"","tile":""},domProps:{"innerHTML":_vm._s(_vm.answer.text)}})],1)],1)],1):_vm._e()],1),_c('div',{staticClass:"rounded pa-2 mb-2",staticStyle:{"border":"1px solid lightgray"}},[_c('h5',[_vm._v(" Верный ")]),_c('p',{staticClass:"mb-0"},[_c('VIcon',{attrs:{"color":_vm.answer.right ? 'success' : 'error'}},[_vm._v(" "+_vm._s(_vm.answer.right ? 'fal fa-check-circle' : 'fal fa-times-circle')+" ")])],1)]),_c('div',{staticClass:"rounded pa-2 mb-2",staticStyle:{"border":"1px solid lightgray"}},[_c('h5',[_vm._v(" Изображение ")]),_c('div',[_c('VImg',{attrs:{"width":"300","height":"300","src":_vm.getGoghaImageUrl({
                      uuid : _vm.answerId,
                      name : _vm.GoghaUploadConstants.NAME_IMAGE,
                      type : _vm.GoghaUploadConstants.TYPE_ANSWER
                    })}})],1)])],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }