<template>
  <TTView>
    <VRow>
      <VCol>
        <VCard>
          <VToolbar flat>
            <VBtn
              icon
              @click="$router.go(-1)"
            >
              <VIcon>fal fa-chevron-left</VIcon>
            </VBtn>

            <VToolbarTitle>Ответ</VToolbarTitle>

            <VSpacer />

            <VTooltip left>
              <template #activator="{ on, attrs }">
                <VBtn
                  icon
                  depressed
                  color="warning"
                  small
                  class="mr-4"
                  :to="{
                    name : Names.R_TALENT_TEST_TOPIC_QUESTION_ANSWER_EDIT,
                    params : {
                      testId,
                      topicId,
                      answerId
                    }
                  }"
                  v-bind="attrs"
                  v-on="on"
                >
                  <VIcon small>
                    fal fa-edit
                  </VIcon>
                </VBtn>
              </template>

              <span>Редактирование ответа</span>
            </VTooltip>

            <VTooltip left>
              <template #activator="{ on, attrs }">
                <VBtn
                  icon
                  depressed
                  color="red"
                  small
                  class="mr-4"
                  v-bind="attrs"
                  v-on="on"
                  @click="removeAnswer(testId, questionId, answerId)"
                >
                  <VIcon small>
                    fal fa-trash
                  </VIcon>
                </VBtn>
              </template>

              <span>Удаление ответа</span>
            </VTooltip>
          </VToolbar>

          <VCardText>
            <VRow>
              <VCol cols="6">
                <VExpansionPanels
                  accordion
                  flat
                >
                  <VExpansionPanel>
                    <VExpansionPanelHeader>JSON</VExpansionPanelHeader>
                    <VExpansionPanelContent>
                      <code
                        class="elevation-0 d-block"
                        style="white-space: pre"
                      >
                        {{ answer }}
                      </code>
                    </VExpansionPanelContent>
                  </VExpansionPanel>
                </VExpansionPanels>

                <VDivider class="my-4" />

                <div>
                  <div
                    style="border: 1px solid lightgray"
                    class="rounded pa-2 mb-2"
                  >
                    <h5>
                      Текст (оригинальный)
                    </h5>
                    <p class="mb-0">
                      {{ answer.originalText }}
                    </p>
                  </div>
                  <VExpansionPanels
                    v-if="answer.text"
                    class="my-4"
                  >
                    <VExpansionPanel>
                      <VExpansionPanelHeader>Текст (HTML)</VExpansionPanelHeader>
                      <VExpansionPanelContent>
                        <!-- eslint-disable vue/no-v-html -->
                        <VCard
                          class="pa-2"
                          outlined
                          tile
                          v-html="answer.text"
                        />
                        <!-- eslint-enable vue/no-v-html -->
                      </VExpansionPanelContent>
                    </VExpansionPanel>
                  </VExpansionPanels>
                </div>

                <div
                  style="border: 1px solid lightgray"
                  class="rounded pa-2 mb-2"
                >
                  <h5>
                    Верный
                  </h5>
                  <p class="mb-0">
                    <VIcon :color="answer.right ? 'success' : 'error'">
                      {{ answer.right ? 'fal fa-check-circle' : 'fal fa-times-circle' }}
                    </VIcon>
                  </p>
                </div>

                <div
                  style="border: 1px solid lightgray"
                  class="rounded pa-2 mb-2"
                >
                  <h5>
                    Изображение
                  </h5>

                  <div>
                    <VImg
                      width="300"
                      height="300"
                      :src="getGoghaImageUrl({
                        uuid : answerId,
                        name : GoghaUploadConstants.NAME_IMAGE,
                        type : GoghaUploadConstants.TYPE_ANSWER
                      })"
                    />
                  </div>
                </div>
              </VCol>
            </VRow>
          </VCardText>
        </VCard>
      </VCol>
    </VRow>
  </TTView>
</template>

<script>
import { getGoghaImageUrl } from '@front.backoffice/common';
import * as GoghaUploadConstants from '../../../../../../components/gogha/common';

export default {
  name: 'AnswerView',
  inject: ['Names'],
  data() {
    return {
      answer: {
        id: '',
        text: '',
        originalText: '',
        right: '',
      },
      GoghaUploadConstants,
    };
  },
  computed: {
    testId() {
      return this.$route.params.testId;
    },
    questionId() {
      return this.$route.params.questionId;
    },
    topicId() {
      return this.$route.params.topicId;
    },
    answerId() {
      return this.$route.params.answerId;
    },
  },
  async created() {
    await this.getCurrentAnswer(this.testId, this.questionId);
  },
  methods: {
    getGoghaImageUrl,

    async getCurrentAnswer(testId, questionId) {
      try {
        const answers = await this.$di.api.Talent.choicesGet({ testId, questionId });
        const currentAnswer = answers.find((answer) => answer.id === parseInt(this.answerId, 10));

        if (currentAnswer) {
          this.answer = currentAnswer;
        } else {
          this.$di.notify.snackError('Не удалось найти ответ');
        }
      } catch (error) {
        this.$di.notify.errorHandler(error);
      }
    },
    async removeAnswer(testId, questionId, answerId) {
      try {
        await this.$di.api.Talent.choiceDelete({ testId, questionId, answerId });
        this.$di.notify.snackSuccess('Ответ успешно удалён');

        this.$router.push({
          name: this.Names.R_TALENT_TEST_TOPIC_QUESTION_ANSWERS,
          params: { testId: this.testId, topicId: this.topicId, questionId: this.questionId },
        });
      } catch (error) {
        this.$di.notify.errorHandler(error);
      }
    },
  },
};
</script>
